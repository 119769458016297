ClickDistribution.Report.vue<template>
    <div class="report">

        <h2 class="border-bottom pb-2 mb-3"><i class="fa fa-signal"></i> Click Distribution Report</h2>


        <div style="background-color: white; padding:2%;">
            <div class="row" style="margin-bottom: 2%">
                <div class="col-12">
                    <div class="form-inline">
                        <div class="form-group mr-1">
                            <select v-model="filter.client_id" class="form-control">
                                <option value="" selected>-- Any Client --</option>
                                <option v-for="client in dropdownClients" :key="client.value" :value="client.value">{{ client.text }}</option>
                            </select>
                        </div>
                        <div class="form-group mr-1">
                            <select v-model="filter.campaign_id" class="form-control">
                                <option value="" selected>-- Any Campaign --</option>
                                <option v-for="campaign in dropdownCampaigns" :key="campaign.value" :value="campaign.value">{{ campaign.text }}</option>
                            </select>
                        </div>
                        <div class="form-group mr-1  pl-2">
                            <select class="form-control" v-model="filter.period">
                                <option selected value="Daily">---Daily---</option>
                                <option value="Hourly">---Hourly---</option>
                            </select>
                        </div>
                        <div class="form-group mr-1">
                            <select v-model="filter.day" class="form-control">
                                <option value="" selected>-- Filter --</option>
                                <option value="1hour">Current hour</option>
                                <option value="3hour">Last 3 hours</option>
                                <option value="6hour">Last 6 hours</option>
                                <option value="12hour">Last 12 hours</option>
                                <option value="today">Today</option>
                                <option value="yesterday">Yesterday</option>
                                <option value="week">This Week</option>
                                <option value="last_week">Last Week</option>
                                <option value="month">This Month</option>
                                <option value="last_month">Last Month</option>
                                <option value="custom">Custom</option>
                            </select>
                        </div>
                        <div v-if="filter.day === 'custom'" class="form-group mr-1">
                            <input type="date" v-model="from" class="form-control date" placeholder="From"/>
                        </div>
                        <div v-if="filter.day === 'custom'" class="form-group mr-1">
                            <input type="date" v-model="to" class="form-control date" placeholder="To"/>
                        </div>
                        <button
                            type="button"
                            class="btn text-white mr-1"
                            @click="reports"
                            style="background-color: #383838"
                        >
                            <i class="fa fa-search" title="Search"></i>
                            Search
                        </button>
                    </div>
                    <button style="float: right;" class="btn btn-md" @click="chart = !chart"><i class="fa fa-line-chart"></i> Chart</button>
                </div>
            </div>

            <div v-if="chart">
                <highcharts :options="chartOptions"></highcharts>
            </div>

            <div>
                <ejs-grid ref='grid' :dataSource="clickDisto" :allowGrouping='true' :allowResizing="true" :allowFiltering='true' :allowSorting='true' :columns="columns" :pageSettings='pageSettings' :allowPaging="true" :detailTemplate ="'detailTemplate'" :allowExcelExport="true" :allowPdfExport="true" :toolbar='toolbar' :toolbarClick='toolbarClick' :excelExportComplete='exportComplete' >
                    <template v-slot:detailTemplate="{data}">
                        <table class="detailtable" width="100%">
                            <tr>
                                <th>Full Url</th>
                                <th>Fold</th>
                                <th>Width</th>
                                <th>Height</th>
                                <th>Area</th>
                                <th>Position Top</th>
                                <th>Position Left</th>
                            </tr>
                            <tr v-for="(item,index) in data.items" :key="index">
                                <td>{{ item && item.fullUrl ? item.fullUrl : 'N/A'}}</td>
                                <td>{{ item && item.fold ? item.fold : 'N/A'}}</td>
                                <td>{{ item && item.width ? item.width : 'N/A'}}</td>
                                <td>{{ item && item.height ? item.height : 'N/A'}}</td>
                                <td>{{ item && item.area ? item.area : 'N/A'}}</td>
                                <td>{{ item && item.top ? item.top : 'N/A'}}</td>
                                <td>{{ item && item.left ? item.left : 'N/A'}}</td>
                            </tr>
                        </table>
                    </template>
                </ejs-grid>
            </div>
        </div>


    </div>
</template>

<script>
import axios from "axios";
import Vue from "vue";
import swal from "sweetalert";
import {ClickEventArgs} from "@syncfusion/ej2-vue-navigations";
import { GridPlugin, Group, Page, ExcelExport, Toolbar, Aggregate, Sort, Filter, DetailRow } from '@syncfusion/ej2-vue-grids';
import {Chart} from "highcharts-vue";
Vue.use(GridPlugin);

export default {
    name: "ClickDistribution.Report",
    props:['user'],
    components: {
        highcharts: Chart
    },
    provide: {
        grid: [ ExcelExport, Toolbar, Aggregate, Group, Page, Sort, Filter, DetailRow ]
    },
    data() {
        return {
            chart: false,
            filter: {
                day: '1hour',
                client_id: '',
                campaign_id: '',
                period:'Daily'
            },
            chartOptions: {
                title: {
                    text: 'Clicks Distribution'
                },
                yAxis: {
                    title: {
                        text: 'Clicks'
                    }
                },
                xAxis: {
                    title: {
                        text: 'Date'
                    }
                },
                series: [
                    {
                        name: 'Clicks',
                        data: []
                    }
                ]
            },
            toolbar: ['ExcelExport'],
            pageSettings: { pageSize: 50 },
            columns: [
                { field: 'client_name', headerText: 'Client', allowGrouping: true, textAlign: 'left' },
                { field: 'campaign_name', headerText: 'Campaign Name', allowGrouping: true, textAlign: 'left' },
                { field: 'date', headerText: 'Date', allowGrouping: true, textAlign: 'left' },
                { field: 'enddate', headerText: 'End Date', allowGrouping: true, textAlign: 'left' },
                { field: 'endtime', headerText: 'End Time', allowGrouping: false, textAlign: 'left' },
                { field: 'total', headerText: 'Total', allowGrouping: false, textAlign: 'left' },
                { field: 'above', headerText: 'Above', allowGrouping: true, textAlign: 'left' },
                { field: 'below', headerText: 'Below', allowGrouping: true, textAlign: 'left' },
                { field: 'hour', headerText: 'Hour', allowGrouping: true, textAlign: 'left' },
                { field: 'status', headerText: 'Status', allowGrouping: true, textAlign: 'left' },
                // { field: 'fullUrl', headerText: 'Url', allowGrouping: true, textAlign: 'left' },
                // { field: 'width', headerText: 'Width', allowGrouping: true, textAlign: 'left' },
                // { field: 'height', headerText: 'Height', allowGrouping: true, textAlign: 'left' },
                // { field: 'area', headerText: 'Area', allowGrouping: true, textAlign: 'left' },
                // { field: 'top', headerText: 'Position Top', allowGrouping: true, textAlign: 'left' },
                // { field: 'left', headerText: 'Position Left', allowGrouping: true, textAlign: 'left' },
            ],
            dropdownClients: [],
            dropdownCampaigns: [],
            from: new Date(),
            to: new Date(),
            clickDisto: '',
        }
    },
    created() {
        this.$root.preloader = false;
        this.GetClientsList();
    },
    methods: {
        GetClientsList: function() {
            let request = {'apikey': this.$root.apikey};

            return axios.get(`${this.$root.serverUrl}/admin/dropdown/client`, {params:request}).then(function(resp){
                //Store the stats
                this.dropdownClients = ( resp.data ) ? resp.data.data : [];
            }.bind(this));
        },
        GetCampaignsList: function() {
            this.filter.campaign_id = '';
            let request = {'apikey': this.$root.apikey};

            return axios.get(`${this.$root.serverUrl}/admin/dropdown/client/campaign/ev/${this.filter.client_id}`, {params:request}).then(function(resp){
                this.dropdownCampaigns = ( resp.data ) ? resp.data.data : [];
            }.bind(this));
        },
        reports() {
            // this.$root.preloader = true
            delete this.filter.allowexport;
            let request = {'apikey': this.$root.apikey, filter: this.filter, to: this.to, from: this.from}
            let filter = Object.assign({ allowexport : false }, this.filter);
            request = Object.assign({ apikey: this.$root.apikey }, {filter: filter, to: this.to, from: this.from});

            axios.get(`${this.$root.serverUrl}/admin/reports/emailverifier/click-breakdown`, { params:request }).then((resp)=>{
                this.clickDisto = ( resp.data && resp.data.data ) ? resp.data.data : [];
                this.$root.preloader = false;

                let data;
                let categories;

                data = resp.data.data.map((series) =>  { return series.total });

                if (this.filter.period === "Daily") {
                    categories = resp.data.data.map((series) =>  { return series.date });
                } else {
                    categories = resp.data.data.map((series) =>  { return series.date + " " + series.hour + ":00" });
                }

                if (categories) this.chartOptions.xAxis.categories = categories;
                else delete this.chartOptions.xAxis.categories;
                this.chartOptions.series[0].data = data;

            }).catch(( err ) => {
                this.$root.preloader = false
                swal({title:'Oops', text: `Sorry, I am unable to complete your request. ${err.message}`, icon: 'error'})
            })
        },
        onSearch(search, loading) {
            if( search.length > 0 ){
                loading(true);
                this.campaignSearch(loading, search, this);
            }
        },
        campaignSearch: function (loading, search, vm) {
            //Load the campaign
            let request = {'apikey': vm.$root.apikey, filters: {}};
            request.query = search;

            axios.get(`${vm.$root.serverUrl}/admin/campaigns/search`, {params: request}).then(function (resp) {
                vm.campaigns = (resp.data && resp.data.data) ? resp.data.data : null;
                loading(false);
            });
        },

        exportComplete() {
            this.$refs.grid.hideSpinner();
        },
        toolbarClick(args = ClickEventArgs) {
            this.$refs.grid.showSpinner();
            switch (args.item.text) {
                /* tslint:disable */
                case 'Excel Export':
                    this.getExcelExportProperties();
                    break;
                /* tslint:enable */
                // case 'PDF Export':
                //     (this.$refs.grid).pdfExport(this.getPdfExportProperties());
                //     break;
            }
        },
        getExcelExportProperties() {
                        
            delete this.filter.allowexport;
            let filter = Object.assign({ allowexport : true }, this.filter);
            
            var request = Object.assign({ apikey: this.$root.apikey }, {filter: filter, to: this.to, from: this.from});

            return axios
                .get(`${this.$root.serverUrl}/admin/reports/emailverifier/click-breakdown`, {
                    params: request,
                    json: true,
                })
                .then(async (resp) => {
                    if (resp == undefined || resp.length == 0) {
                        this.$refs.grid.hideSpinner();
                        swal({title:'Error', text: `Error Exporting Data `, icon: 'Error',timer: 3000})
                    } else {
                        let clickDisto = ( resp.data && resp.data.data ) ? resp.data.data : [];
                        var fileURL = window.URL.createObjectURL(new Blob([clickDisto]));
                        var fileLink = document.createElement('a');
                        fileLink.href = fileURL;
                        fileLink.setAttribute('download', `Click Distribution.csv`);
                        document.body.appendChild(fileLink);

                        fileLink.click();
                        this.$refs.grid.hideSpinner();
                        swal({title:'Completed', text: `Export has been downloaded `, icon: 'success',timer: 3000})
                        // this.$root.preloader = false;
                    }
                    this.eLoading = false;
                    this.preloader = this.$root.preloader = false;
                });
        },
    },
    watch:{
        "filter.client_id": function(){
            if(this.filter.client_id.length > 0){
                this.GetCampaignsList();
            }else{
                this.dropdownCampaigns = [];
                this.filter.campaign_id = "";
            }
        }
    }
}
</script>

<style scoped>

</style>
